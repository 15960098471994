import React, {Component} from 'react';
import {FlexibleXYPlot,
  FlexibleWidthXYPlot,
  FlexibleHeightXYPlot, LineSeries, VerticalGridLines,HorizontalGridLines,XAxis, YAxis,VerticalBarSeries,HorizontalBarSeries, Hint, DiscreteColorLegend, Crosshair, RadialChart, LabelSeries} from 'react-vis';
import _ from "lodash"
import ReactMarkdown from "react-markdown"


class Graph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crosshairValues: [],
      crosshairItems: [],
      value: false,
      barvalue:false
    };
  }
  GetCrosshairItems(){
    let Items =[]
    let i = 0
    for (let items of this.props.data){
      Items.push({title: items.title, item: items.Data[i].y})
      i++
    }

  }

  componentDidMount(){

  }

  _onMouseLeave = () => {
    this.setState({crosshairValues: []});
  };
  _onNearestX = (value, {index}) => {
let Crossdata = this.props.data.Data.map(d => d.Data[index])
let CrostitleData = []
this.props.data.Data.map((document, index) => (
  CrostitleData.push({title: document.title, value: Crossdata[index].y})
))

  this.setState({crosshairItems: CrostitleData});
  this.setState({crosshairValues: this.props.data.Data.map(d => d.Data[index])});

};

render() {
switch(this.props.data.Type){
case "Bar_line":
case "Bar":
case "Line":

// let GraphData



for (let data of this.props.data.Data){

  if(data.Json_data !== null){
      // console.log("json", data.Json_data)
    if (data.Json_data !== undefined){
        //console.log("jsonData",data.Json_data[0].Data)
        data.Data = data.Json_data[0].Data
      }
}

}


return (
  <div>
  <div className="ChartTitle">{this.props.data.title}</div>
  <FlexibleWidthXYPlot animation={2} height={300} xType="ordinal" onMouseLeave={this._onMouseLeave}>
    <VerticalGridLines />
    <HorizontalGridLines />
    {this.props.data.Data[0].Data.length> 15 ? (<XAxis tickFormat={(t, i) => {
                if ((i + 1) % Math.round(this.props.data.Data[0].Data.length/10) === 0) {

                  return t.split(',')[0];
                } else {
                  return;
                }
              }}/>)
              :(<XAxis/>)
            }

    <YAxis  itle={this.props.data.Yaxis}/>

  {this.props.data.Data.map((document, index) => (

  document.Type === "bar" ? (
    <VerticalBarSeries animation onNearestX={this._onNearestX} color={document.Color} style={{opacity: 0.7}} data={document.Data}>
</VerticalBarSeries>

  ):(
    <LineSeries animation style={{stroke: document.Color, strokeWidth: 3, strokeLinejoin: "round"}} onNearestX={this._onNearestX} data={document.Data}>

    </LineSeries>
  )


  ))}
  <Crosshair
         values={this.state.crosshairValues}
         className={'test-class-name'}
         titleFormat={(d) => ({value: this.state.crosshairValues[0].x})}
         itemsFormat={(d) => this.state.crosshairItems}

       />


  </FlexibleWidthXYPlot>
 <DiscreteColorLegend style={{whiteSpace: 'normal'}} items={this.props.data.Data.map(d => d.title)} colors={this.props.data.Data.map(d => d.Color)} orientation="horizontal"/>
<div className="GraphCaption">  <ReactMarkdown source={this.props.data.caption} linkTarget='_blank'/></div>
  </div>
)



  break;
  case "Horizontal_bar":

//console.log(this.props.data.Data)
 let swappedArray =[]
 let swappedLabel =[]

 let barHeight = 300/((this.props.data.Data.length*this.props.data.Data[0].Data.length)+this.props.data.Data.length)


 let offset = 0
   let i = 0
for(let item of this.props.data.Data) {
  let swappedData = []
  if(i === 0){
    offset = -barHeight/this.props.data.Data.length
  }else{
    offset = barHeight/this.props.data.Data.length
  }

    for (let swap of item.Data){
    swappedData.push({y:swap.x, x:swap.y,label:swap.y})
    // swappedLabel.push({y:swap.x, x:swap.y,label:Math.round( swap.y * 10) / 10,yOffset:offset,xOffset:3})
        swappedLabel.push({y:swap.x, x:swap.y,label:Math.round( swap.y * 10) / 10,yOffset:0,xOffset:3})


  }
  i++
  swappedArray.push(swappedData)

}


let range = _.range(0, _.maxBy(swappedLabel, 'x').x).length
const {barvalue} = this.state;




//console.log(swappedArray)
// swappedArray = [[{y: 5, x: 10}, {y: 10, x: 5}, {y: 20, x: 15}],[{y: 5, x: 12}, {y: 10, x: 2}, {y: 20, x: 11}]]
  return (
    <div>
    <div className="ChartTitle">{this.props.data.title}</div>
    <FlexibleWidthXYPlot margin={{left: 80}} animation={2} height={300} yType="ordinal" >

      <VerticalGridLines />
      <HorizontalGridLines />
      {range > 1000 ? (<XAxis tickFormat={(t, i) => {
                  if ((i + 1) % 5 === 0) {
                    return t;
                  } else {
                    return;
                  }
                }}/>)
                :(<XAxis/>)
              }
      <YAxis position={'middle'} title={this.props.data.Yaxis}/>

    {this.props.data.Data.map((document, index) => (


      <HorizontalBarSeries animation color={document.Color} style={{opacity: 0.7}} data={swappedArray[index]}>

  </HorizontalBarSeries>
    ))}
<LabelSeries data={swappedLabel} allowOffsetToBeReversed={false} labelAnchorY={"middle"} style={{fontSize:"0.6rem", marginLeft:"10px", fontFamily:'aktiv-grotesk, Helvetica, Arial, sans-serif',fontWeight:900,}}/>


    </FlexibleWidthXYPlot>
   <DiscreteColorLegend items={this.props.data.Data.map(d => d.title)} colors={this.props.data.Data.map(d => d.Color)} orientation="horizontal"/>
  <div className="GraphCaption"><ReactMarkdown source={this.props.data.caption} linkTarget='_blank'/></div>
    </div>
  )



    break;
    case "Pie":
    case "Radial":
const {value} = this.state;
let Data = []
    for(let slice of this.props.data.Data){
      Data.push({angle:slice.angle,color:slice.color,name:slice.Label,sub:slice.sublabel})
    }
    let inner = 0
    let outer = 140
if(this.props.data.Type === "Radial"){
   inner = 100
   outer = 140
}

//console.log(outer)

    //console.log(Data)
    return (
      <div>
      <div className="ChartTitle">{this.props.data.title}</div>
      <RadialChart
     colorType={'literal'}
     radius={outer}
     innerRadius={inner}
     margin={{top: 100}}
     getLabel={d => d.name}
     data={Data}
     labelsRadiusMultiplier={1.4}
     labelsStyle={{fontSize: 16, fill: '#222'}}
     showLabels={false}
     style={{stroke: '#fff', strokeWidth: 2}}
     onValueMouseOver={v => this.setState({value: v})}
     onSeriesMouseOut={v => this.setState({value: false})}
     width={300}
     height={300}
     padAngle={0.04}
   >

  {value !== false && <div style={{width:'120px',position:'absolute', top:"35%", left:'30%'}}>
  <p style={{fontFamily:'aktiv-grotesk, Helvetica, Arial, sans-serif',textAlign:'center',fontWeight:900, fontSize:'1rem', color:'#000000'}}>{value.name}</p>
  <p style={{fontFamily:'urw-din-condensed, Helvetica, Arial, sans-serif',textAlign:'center', fontSize:'2rem', color:'#8dc63f'}}>{value.sub}</p>
</div>}

</RadialChart>
 <DiscreteColorLegend items={Data.map(d => d.name)} colors={Data.map(d => d.color)} orientation="horizontal"/>
    <div className="GraphCaption"><ReactMarkdown source={this.props.data.caption} linkTarget='_blank'/></div>

      </div>
    )



      break;

  default:

return(<></>)
}



  }

}
export default Graph;
