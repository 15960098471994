import React  from 'react'
import ReactTooltip from 'react-tooltip';



//
const Tooltip = props => {


    if(props.children.length === 0){
            if(props.href === "#"){
              return (
                <>
              <span data-tip={props.title} className="no-underline" ><sup className="no-underline">[*]</sup></span>
              <ReactTooltip place="top" type="dark" effect="float"/>
              </>
              );

            }else{
                return (
                  <>
                <span data-tip={props.title} className="no-underline" ><a className="no-underline" href={props.href} target={props.href.includes("http")? "_blank" : "_self"}><sup className="no-underline">[*]</sup></a></span>
                <ReactTooltip place="top" type="dark" effect="float"/>
                </>
                );
            }
    }else{
     return(
       <a href={props.href} rel="noreferrer" target={props.href.includes("http")? "_blank" : "_self"}>{props.children}</a>
     )
    }

};


export default Tooltip;
