import React , {useState} from 'react'
import { InView } from 'react-intersection-observer'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger';
// import ReactMarkdown from "react-markdown"
import ThreeDepth from '../ThreeD/DepthImage'
import { BgImage } from 'gbimage-bridge';
import { getImage } from "gatsby-plugin-image"

//
const DepthImage = ({ children, data, pinspace, scrubspeed,anticipate,location}) => {
    const [inview, viewtoggle] = useState(false);
    let revealRef = React.createRef();
    const mediaQuery = typeof window !== "undefined" && window.innerWidth;


  return(

<div ref={revealRef} className="relative DContainer">
    <div className="absolute bg-gradient-to-l md:from-Grennery-bg50  from-Grennery-bg80 w-full h-full">
      <div className="absolute text-right transform md:-translate-y-1/3 md:top-1/3 top-1/4 md:right-12 right-6">

        <h1 className=" text-white lg:text-10xl md:text-7xl text-4xl hsm:text-8xl hsm:pt-10 leading-vtight " >{data.title}</h1>
        <div className="flex">

        <div className="ml-auto text-right text-white md:text-2-5xl text-2xl italic leading-tight lg:w-3/6 md:w-4/6 w-5/6 md:mt-16 hsm:mt-8 mt-6" >{data.Sub_message}</div>

        </div>

            </div>
      </div>

      <InView as="div"  className="DepthContainer" threshold={0.3} onChange={(inView, entry) => viewtoggle(inView)}>
        {mediaQuery <= 640 ?
            <BgImage image={getImage(data.Header_image.localFile)} Tag="div" className="h-full w-full bg-offhead bg-cover"/>
        :      <ThreeDepth dataDepth={data.HeaderDepthImage} dataImage={data.Header_image} reduceMotion={data.reduceMotion} inview={inview}/>
        }

        </InView>


</div>

);
};


export default DepthImage;
