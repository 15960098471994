import React from 'react';

import ReactMarkdown from "react-markdown"
import StrapiImage from '../components/StrapiImage_new.js'
import Graph from "../components/graph.js"
import Video from "./WistiaEmbed.js"
import Tooltip from "./tooltip.js"
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import withLocation from "./withLocation"





const ArticleContent = props => {

  const renderers = {
    link: Tooltip
  };



  let Location = props.location.pathname

    switch(props.data.strapi_component) {

case "content.content":


switch(props.data.Type) {
  case "Pullout":
  return(
  <div className={`bg-${props.data.BG_colour} p-5 prose prose-${props.data.BG_colour} max-w-none text-lg`}>
  <ReactMarkdown renderers={renderers} source={props.data.Content} escapeHtml={false} linkTarget='_blank'/>
  </div>
  )
  break;
  case "Quote":
  return(
    <blockquote className="relative text-xl italic quote">
    <div className="aecom-quote-mark-top" aria-hidden="true">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 174"><path d="M74.1 13.1C33.5 37.3 4 72.1 4 115.7c0 27.1 14.8 51.9 45.4 51.9 24.7 0 41.3-15.9 41.3-37.7 0-16.1-9.5-30.6-24.1-37.1l-17.7 17.1c-6.1-3.4-9.6-10.1-8.9-17.1 0-25.3 22.4-46 46.5-61.3L74.1 13.1zm106.4 0c-40.7 24.1-70.1 58.9-70.1 102.5 0 27.1 14.8 51.9 45.4 51.9 24.7 0 41.3-15.9 41.3-37.7 0-16.1-9.5-30.6-24.1-37.1l-17.7 17.1c-6.1-3.4-9.5-10.1-8.8-17.1 0-25.3 22.4-46 46.5-61.3l-12.5-18.3z"/></svg>
    </div>
      <div className="mb-2 text-AECOM_Activation prose max-w-none text-xl">  <ReactMarkdown source={props.data.Content} escapeHtml={false} linkTarget='_blank'/></div>
      <div className="aecom-quote-mark-bottom" aria-hidden="true">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 174"><path d="M127 167.6c40.6-24.2 70.1-59 70.1-102.6 0-27.1-14.8-51.9-45.4-51.9-24.7 0-41.3 15.9-41.3 37.7 0 16.1 9.5 30.6 24.1 37.1l17.7-17.1c6.1 3.4 9.6 10.1 8.9 17.1 0 25.3-22.4 46-46.5 61.3l12.4 18.4zm-106.4 0c40.7-24.1 70.1-58.9 70.1-102.5 0-27.1-14.8-51.9-45.4-51.9C20.6 13.2 4 29.1 4 50.9 4 67 13.5 81.5 28.1 88l17.7-17.1c6.1 3.4 9.5 10.1 8.8 17.1 0 25.3-22.4 46-46.5 61.3l12.5 18.3z"/></svg>
      </div>
    </blockquote>
  );
  default:
  return(
    <div className="prose max-w-none text-lg">
    <ReactMarkdown renderers={renderers} source={props.data.Content} escapeHtml={false} linkTarget='_blank'/>
    </div>
  )
}


break;
case "content.image":


return(

<figure className="my-5">
<StrapiImage imageInfo={props.data.Image.localFile}/>
<figcaption className="text-sm mt-1">{props.data.caption}</figcaption>
</figure>)

break;
case "content.podcast":



break;
case "content.podcast":
return(
  <div className="audioPlayer">
  <h4 className="mb-5">{props.data.title}</h4>
  <AudioPlayer
    src={props.data.mp3.publicURL}
    customAdditionalControls={[]}
    onPlay={e => typeof window !== "undefined" && window.gtag("event", "Podcast" ,{"event_category":"Play" ,"event_label":Location})}
    // other props here
  />
  <a className="mt-2" rel="noreferrer" href={props.data.link} target="_blank">{props.data.teaser}</a>
  </div>)



break;
case "content.video":

  return( <Video hashedId={props.data.wistia_id} playerColor={props.data.color} />)

break;
default:
return(
<></>
)
}






}
export default withLocation(ArticleContent);
