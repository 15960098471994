import React, { Component } from 'react'
import { Link, graphql,withPrefix ,navigate} from 'gatsby'


import ReactMarkdown from "react-markdown"
import ArticleContent from "../components/articleContent_new"
import DepthHeader from "../components/depth_header"
import { connect } from 'react-redux';
import { clicktoSection  } from '../actions';
import HomeImage from "../images/home-01.svg"
import SEO from "../components/seo"

import { BgImage } from 'gbimage-bridge';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import StrapiImage from "../components/StrapiImage_new.js"
import ReadingTime from "reading-time"



class ArticlesTemplate extends Component {
  constructor(props) {
    super(props)

    this.layoutContents = React.createRef()
    this.layoutWrapper = React.createRef()
    this.returnImage = this.returnImage.bind(this)
    this.scrolltoclick = this.scrolltoclick.bind(this)
  }

  componentDidMount(){



  }



  returnImage(image){
if(image === null){
return ([
`linear-gradient(94deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6012780112044818) 30%, rgba(0,0,0,0.604079131652661) 70%, rgba(0,0,0,0) 100%)`
].reverse()
)
}else{
return ([
image.childImageSharp.fluid,
`linear-gradient(94deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6012780112044818) 30%, rgba(0,0,0,0.604079131652661) 70%, rgba(0,0,0,0) 100%)`
].reverse()
)
}

};

scrolltoclick(e, section){

  e.stopPropagation()

    this.props.clicktoSection(section)

      navigate(this.props.location.state.returnPage.replace(withPrefix("/"),"/"))


}



  render() {


let OG = ""
if(this.props.data.strapiArticles.Header_image !== null){
  OG = this.props.data.strapiArticles.Header_image.publicURL
}

// console.log(this.props.data.strapiArticles.Content)
let Content=[]
// let Reading = ""
this.props.data.strapiArticles.Content.map((content, index, array) => {

// Reading += content.Content
// Reading += content.response

if(content.position !== "right"){
if(array[index+1] !== undefined && array[index+1].position === "right"){
Content.push({left:content,right:array[index+1]})

}else{
  Content.push({left:content,right:null})
}
}


})
// let Read = ReadingTime(Reading)
// console.log(Content)


    return (

      <div>

  <SEO metaImage={OG} description={this.props.data.strapiArticles.Meta_description} title={this.props.data.strapiArticles.title}/>

  <article id="article" itemScope itemType="https://schema.org/Article">
        <div itemProp="publisher" itemScope itemType="https://schema.org/Organization">
            <meta itemProp="name" content="AECOM"/>
        </div>
<DepthHeader data={this.props.data.strapiArticles} location={this.props.location}  scrubspeed={this.Scrubspeed} anticipate={false}/>


    <main className="w-full antialiased py-12 text-base bg-DarkGreenery-bg h-full">
    {Content.map((document, index) => (
          <div key={`content${index}`} className="mx-auto max-w-6xl md:flex px-6 md:px-12 lg:px-6 md:my-3 my-1">
            <div className="w-auto md:w-2/3">
              <ArticleContent data={document.left} key={index} Homepage={false} />

            </div>

            <div className="w-auto md:w-1/3 md:ml-10 md:my-0 my-3">
            {document.right !== null ?
              <ArticleContent data={document.right} key={index} Homepage={false} />
            :<></>}

            </div>
          </div>
    ))}

    <div className="w-full antialiased h-full">
          <div className="font-display md:text-4xl mx-auto max-w-6xl md:flex px-6 md:px-12 lg:px-6 mt-10 ">
    {this.props.location.state !== null && this.props.location.state !== undefined ? this.props.location.state.returnPage !== null && this.props.location.state.returnPage !== undefined?  <div className="ml-auto md:text-3xl text-right md:w-1/4 w-1/2 text-3xl cursor-pointer pr-5" onClick={(e) =>  this.scrolltoclick(e,this.props.location.state.section)} >{`< ${this.props.location.state.linkText}`}</div>:<></>:<></>}
          </div>
          </div>


    </main>

        </article>

</div>
)
}
}
function mapStateToProps(state) {
  return {
    ClickSection:state.ClickSection
  };
}
export default connect(mapStateToProps,{clicktoSection})(ArticlesTemplate);

export const query = graphql`
  query strapiArticlesTemplate($slug: String!) {
    strapiArticles(slug: {eq:$slug}) {
      Meta_description
      Type
      slug
      reduceMotion
      Sub_message
      authors {
      Social
      email
      name
      position
      image {
    localFile{
      childImageSharp {
        gatsbyImageData(quality: 100, width: 1920, placeholder: BLURRED)

                  }
                }
    }
  }
      Header_image {
          localFile{
          publicURL
          childImageSharp {
              gatsbyImageData(quality: 100, width: 1920, placeholder: BLURRED)
          }
      }
    }
      HeaderDepthImage {
          localFile{
          publicURL
          childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 50, placeholder: BLURRED)
          }
        }
      }
      title
      Content {
         strapi_component
            Content
            BG_colour
            position
            caption
            Type
            wistia_id
            link
            mp3 {
                localFile{
              publicURL
            }
          }
            title
            teaser
            color
            Image {
                localFile{
              childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 50)
              }
            }

            }





        }
      }




}
`
